<template>
  <div>
    <form class="form" @submit.stop.prevent="formOnsubmit()">
      <!--begin: Wizard Step 1-->
      <div
        class="pb-5"
        data-wizard-type="step-content"
        data-wizard-state="current"
      >
        <h5 class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg">
          <span>
            {{ title }}
          </span>
        </h5>

        <b-form-group
          id="input-group-pitching-event-id"
          label="Pitching Event:"
          label-for="input-pitching-event-id"
        >
          <template #append>
            <b-input-group-text><i class="fas fa-folder"></i></b-input-group-text>
          </template>
          <b-form-input
            id="input-pitching-event-id"
            v-model="display.pitching_event_name"
            @click="$bvModal.show('modal-pitching-events')"
            placeholder="Pitching Event"
            readonly
          ></b-form-input>
          <small class="text-danger">{{ error.pitching_event_id }}</small>
        </b-form-group>

        <b-form-group label="Video Mute" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="radio-group-video-mute"
            v-model="form.video_mute"
            :options="optionsVideoMute"
            :aria-describedby="ariaDescribedby"
            name="radio-options-video-mute"
          ></b-form-radio-group>
        </b-form-group>

        <b-form-group label="Mic Mute" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="radio-group-mic-mute"
            v-model="form.mic_mute"
            :options="optionsMicMute"
            :aria-describedby="ariaDescribedby"
            name="radio-options-mic-mute"
          ></b-form-radio-group>
        </b-form-group>

        <b-form-group label="Berbagi Layar" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="radio-group-sharing-screen"
            v-model="form.sharing_screen"
            :options="optionsSharingScreen"
            :aria-describedby="ariaDescribedby"
            name="radio-options-sharing-screen"
          ></b-form-radio-group>
        </b-form-group>

        <!-- <b-form-group
          id="input-group-video-resolution"
          label="Video Resolusi:"
          label-for="input-video-resolution"
        >
          <b-form-input
            id="input-video-resolution"
            v-model="form.video_resolution"
            placeholder="Video Resolusi"
          ></b-form-input>
          <small class="text-danger">{{ error.video_resolution }}</small>
        </b-form-group> -->

        <b-form-select
          v-model="form.video_resolution"
          :options="optionsVideoResolution"
        ></b-form-select>
      </div>
      <!--end: Wizard Step 1-->

      <!--begin: Wizard Actions -->
      <div class="d-flex justify-content-between border-top pt-10">
        <div>
          <b-button type="submit" variant="primary">Simpan</b-button>
          <b-button
            type="button"
            class="ml-2"
            variant="default"
            @click="$router.push('/pitching-video-conf-settings')"
          >
            Batal
          </b-button>
        </div>
      </div>
      <!--end: Wizard Actions -->
    </form>

    <!-- Modal Pitching Event -->
    <ModalPitchingEvent @choosePitchingEvent="choosePitchingEvent" />
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ModalPitchingEvent from "@/view/components/general/ModalPitchingEvent.vue";

export default {
  props: {
    form: Object,
    route: String,
    title: String,
    purpose: {
      type: String,
      default: "add"
    },
  },
  components: {
    ModalPitchingEvent,
  },
  data() {
    return {
      display: {
        pitching_event_name: "",
      },
      error: {
        pitching_event_id: "",
        video_mute: "",
        mic_mute: "",
        sharing_screen: "",
        video_resolution: "",
      },
      optionsVideoMute: [
        { text: "Aktif", value: 1 },
        { text: "Tidak Aktif", value: 0 },
      ],
      optionsMicMute: [
        { text: "Aktif", value: 1 },
        { text: "Tidak Aktif", value: 0 },
      ],
      optionsSharingScreen: [
        { text: "Aktif", value: 1 },
        { text: "Tidak Aktif", value: 0 },
      ],
      optionsVideoResolution: [
        { value: '', text: "Pilih resolusi video", disabled: true },
        { value: 240, text: "240p" },
        { value: 360, text: "360p" },
        { value: 480, text: "480p" },
        { value: 1080, text: "1080p" }
      ],
    };
  },
  methods: {
    choosePitchingEvent(value) {
      console.log(value);
      this.form.pitching_event_id = value.id;
      this.display.pitching_event_name = value.name;
      this.$bvModal.hide("modal-pitching-events");
    },

    validationMessage() {
      Swal.fire({
        title: "Gagal",
        text: "Harap periksa kembali form",
        icon: "error",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    },

    async formOnsubmit() {
      // Make Request
      let response = await module.submit(this.form, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire({
          title: response.success.title,
          text: response.success.message,
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.$router.push("/pitching-video-conf-settings");
      }
    },
  },
  watch:{
    form: function (newVal, oldVal) {
      if (this.purpose == "edit") {
        this.display.pitching_event_name = this.form.pitching_event_name
      }
    },
  }
};
</script>