<template>
  <div>
    <b-modal
      id="modal-pitching-events"
      title="Pitching Event"
      size="xl"
      hide-footer
    >
      <Table purpose="modal" @choosePitchingEvent="choosePitchingEvent" />
    </b-modal>
  </div>
</template>

<script>
import Table from "@/view/components/pitching-events/Table.vue";
export default {
  components: {
    Table,
  },
  methods: {
    choosePitchingEvent(value) {
      this.$emit("choosePitchingEvent", value);
    },
  },
};
</script>

<style>
</style>