<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div>
              <!-- Filter -->
              <!-- <div class="row justify-content-end mt-n3">
                <div class="col-md-4">
                  <b-input-group>
                    <b-form-input
                      type="text"
                      v-model="filter.name"
                      placeholder="Saring Nama"
                      @keyup="filterByName"
                    ></b-form-input>
                  </b-input-group>
                </div>
              </div> -->
              <b-table
                striped
                hover
                :items="items"
                :fields="fields"
                class="mt-3"
                responsive
              >
                <template #table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{ width: field.key === 'action' ? '15%' : '' }"
                  />
                </template>

                <template #cell(action)="data">
                  <template v-if="purpose == 'master'">
                    <b-button
                      size="sm"
                      class="mr-1 btn-circle btn-outline-info"
                      v-b-tooltip.hover
                      title="Detail"
                      @click="
                        $router.push({
                          path: '/pitching-events/detail/' + data.item.id,
                        })
                      "
                      ><i class="fas fa-eye px-0"></i
                    ></b-button>
                    <b-button
                      size="sm"
                      class="mr-1 btn-circle btn-outline-success"
                      v-b-tooltip.hover
                      title="Edit"
                      @click="
                        $router.push({
                          path: '/pitching-events/edit/' + data.item.id,
                        })
                      "
                      v-if="data.item.status != 'special' && btnAccess"
                      ><i class="fas fa-edit px-0"></i
                    ></b-button>
                    <b-button
                      size="sm"
                      class="btn-circle btn-outline-danger"
                      v-b-tooltip.hover
                      title="Hapus"
                      @click="deleteData(data.item.id)"
                      v-if="data.item.status != 'special' && btnAccess"
                      ><i class="fas fa-trash px-0"></i
                    ></b-button>
                  </template>
                  <template v-if="purpose == 'modal'">
                    <b-button
                      variant="primary"
                      @click="
                        choosePitchingEvent({
                          id: data.item.id,
                          name: data.item.name,
                        })
                      "
                      >Pilih</b-button
                    >
                  </template>
                </template>
              </b-table>

              <b-pagination
                v-if="items.length != 0"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                @page-click="pageOnClick"
                class="mt-4"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import { getUser, setUser } from "@/core/services/jwt.service.js";

export default {
  props: {
    purpose: String,
  },
  data() {
    return {
      // filter
      filter: {
        name: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      fields: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "start_time_display",
          label: "Mulai",
          sortable: true,
        },
        {
          key: "end_time_display",
          label: "Selesai",
          sortable: true,
        },
        {
          key: "status_name",
          label: "Status",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          tdClass: "nowrap",
        },
      ],
      items: [],
      // access
      btnAccess: true,
    };
  },
  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },
    
    async pagination() {
      let filterParams = `&name=${this.filter.name}`;
      let response = await module.paginate(
        "api/pitching-events",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },

    choosePitchingEvent(value) {
      this.$emit('choosePitchingEvent', value)
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete(`api/pitching-events/${id}`);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1001") {
          this.classroomView = true;
        }
        if (access_right[a] == "1002") {
          this.btn = true;
          this.classroomView = true;
        }
        if (access_right[a] == "1003") {
          this.btnJoin = true;
          this.classroomView = true;
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Pitching Event" }]);
    this.pagination();
  },
};
</script>